<!--
* @FileDescription: 服务项目管理
* @Author: chenz
* @Date: 2021/4/28
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="service-items-manage">
    <!-- 查询表单 -->
    <CommonQueryFrom
      :queryForm="queryForm"
      :queryFormItemList="queryFormItemList"
      @_add="_add"
      @_query="_query"
      @_export="_export"
    ></CommonQueryFrom>
    <!-- 表格 -->
    <CommonTable
      :tableData="tableData"
      :columns="columns"
      :loading="loading"
      :pagingOptions="pagingOptions"
      @_del="_del"
      @_edit="_edit"
      @_sizeChange="_sizeChange"
      @_currentChange="_currentChange"
      @_handleSelectionChange="_handleSelectionChange"
    >
    </CommonTable>
    <!-- 弹框表单 -->
    <CommonDialogForm
      :title="dialogTitle"
      :visible="visible"
      :form="form"
      ref="form"
      :rules="rules"
      :formItemList="formItemList"
      @_Close="_Close"
      @_cancel="_cancel"
      @_confirm="_confirm"
      @handleAvatarSuccess="handleAvatarSuccess"
    >
    </CommonDialogForm>
  </div>
</template>

<script>
// 引入侧边栏json
const formItemList = require("./formItemList")

// 引入接口
import {
  getProject,
  addProject,
  delProject,
  updateProject,
} from "../../api/service-items-manage"

import {
  getStoreOptions,
  getProjectOptions,
  getProjectClassifyOptions,
} from "../../api/select-options"

// 引入方法
import { getExcelLabelOrProp } from "../../utils/tool"

import http from "../../api/http"

export default {
  name: "ServiceItemsManage",

  data() {
    return {
      // 查询表单
      queryForm: {
        name: "",
        storeId: "",
      },

      // 查询表单项
      queryFormItemList: [
        { type: "input", prop: "name", placeholder: "请输入要查询的项目名称" },
        {
          type: "input",
          prop: "storeId",
          placeholder: "请输入要查询的项目店铺",
        },
      ],

      // 列接受的参数
      columns: [
        { label: "项目名称", prop: "name", width: 120 },
        { label: "所属店铺", prop: "storeName", width: 120 },
        { label: "项目分类", prop: "classifyName", width: 120 },
        {
          label: "项目介绍",
          prop: "projectIntroduce",
          width: 200,
          showOverFlowTooltip: true,
        },
        { label: "项目时长", prop: "duration", width: 120 },
        // { label: "项目价格", prop: "duration", width: 120 },
        { label: "项目图片", prop: "image", width: 120 },
        {
          label: "项目流程",
          prop: "projectProcess",
          width: 200,
          showOverFlowTooltip: true,
        },
        {
          label: "保养方法",
          prop: "maintainMethod",
          width: 200,
          showOverFlowTooltip: true,
        },
        {
          label: "适用人群",
          prop: "fitCrowd",
          width: 200,
          showOverFlowTooltip: true,
        },
        {
          label: "禁忌人群",
          prop: "tabooCrowd",
          width: 200,
          showOverFlowTooltip: true,
        },
      ],

      // 具体数据
      tableData: [],

      // 选择的表格数据
      selectChangeTableData: [],

      // 分页
      pagingOptions: {
        limit: 10,
        page: 1,
        total: 0,
      },

      // 加载
      loading: false,

      // 弹框标题
      dialogTitle: "添加服务项目",

      // 弹框显示
      visible: false,

      // 表单
      form: {
        name: "",
        classifyId: "",
        storeIds: [],
        storeId: "",
        duration: "",
        image: "",
        projectIntroduce: "",
        fitCrowd: "",
        tabooCrowd: "",
        projectProcess: "",
        maintainMethod: "",
      },

      // 校验
      rules: {
        name: [
          { required: true, message: "项目名称不能为空", trigger: "change" },
        ],
        classifyId: [
          { required: true, message: "项目分类不能为空", trigger: "change" },
        ],
        storeIds: [
          { required: true, message: "所属店铺不能为空", trigger: "change" },
        ],
        duration: [
          { required: true, message: "项目时长不能为空", trigger: "change" },
        ],
        image: [
          { required: true, message: "项目图片不能为空", trigger: "change" },
        ],
        projectIntroduce: [
          { required: true, message: "项目介绍不能为空", trigger: "change" },
        ],
        fitCrowd: [
          { required: true, message: "适用人群不能为空", trigger: "change" },
        ],
        tabooCrowd: [
          { required: true, message: "禁忌人群不能为空", trigger: "change" },
        ],
        projectProcess: [
          { required: true, message: "项目流程不能为空", trigger: "change" },
        ],
        maintainMethod: [
          { required: true, message: "保养方法不能为空", trigger: "change" },
        ],
      },

      // 表单数据
      formItemList: formItemList,

      baseURL: "",

      imageTemp: "",

      addLine: false,
    }
  },

  mounted() {
    this.baseURL = http.defaults.baseURL
    this.getData()
  },

  methods: {
    // 查询
    _query(form) {
      this.loading = true
      this.getData()
    },

    // 添加
    _add() {
      this.form = {}
      // this.form.storeIds = []
      this.dialogTitle = "添加服务项目"
      this.visible = true
      const index = this.formItemList.findIndex((_) => _.flag === true)
      // const index = this.formItemList.findIndex((_) => _.prop === 'classifyId')
      console.log("添加", this.formItemList[index].options)
      if (this.addLine) {
        console.log("addLines", this.selectChangeTableData[0])
        this.form = this.selectChangeTableData[0]
        this.form.id = ""
        this.form.storeIds = this.form.storeIds || [this.form.storeId]
      }

      //修改多选改为单选
      // this.formItemList[index].multiple = true
      // this.formItemList[index].prop = "storeIds"
      // this.formItemList[index].value = "storeIds"
      this.$nextTick(() => {
        // this.$nextTick(() => {
        // console.log(222222, this.$refs.form.$refs)
        // this.$refs.form.$refs.uploadAvatar[0].clearFiles()
        this.$refs.form.$refs.form.clearValidate()
        // })
      })
    },

    // 导出
    _export(form) {
      import("../../vendor/Export2Excel").then((excel) => {
        const tHeader = getExcelLabelOrProp("编号", "label", this.columns)
        const filterVal = getExcelLabelOrProp("serial", "prop", this.columns)
        const list =
          this.selectChangeTableData.length > 0
            ? this.selectChangeTableData
            : this.tableData
        console.log(
          "_export",
          this.selectChangeTableData.length,
          this.selectChangeTableData
        )
        console.log("this.tableData", this.tableData)
        console.log("list", list)
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "项目管理",
          bookType: "xlsx",
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },

    // 多选事件
    _handleSelectionChange(val) {
      // console.log("多选事件", val)
      this.selectChangeTableData = val
      console.log("length", this.selectChangeTableData.length)
      this.addLine = this.selectChangeTableData.length === 1
    },

    // 修改
    _edit(row) {
      console.log(row)
      this.dialogTitle = "修改项目"
      this.visible = true
      this.form = Object.assign({}, row)
      // const index = this.formItemList.findIndex((_) => _.flag === true)
      // //修改多选改为单选
      // this.formItemList[index].multiple = false
      // this.formItemList[index].prop = "storeId"
      // this.formItemList[index].value = "storeId"
      this.form.storeIds = [this.form.storeId]

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.clearValidate()
        })
      })
      // this.form = JSON.parse(JSON.stringify(row))
    },

    // 删除
    _del(row) {
      // console.log("删除", row)
      this.$confirm("此操作将删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await delProject(row.id)
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            })
            this.getData()
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },

    // 每页多少条事件
    _sizeChange(val) {
      this.pagingOptions.limit = val
      this.getData()
    },

    // 当前页事件
    _currentChange(val) {
      this.pagingOptions.page = val
      this.getData()
    },

    // 取消
    _cancel() {
      this.closeForm()
    },

    // 确定
    _confirm() {
      this.$refs.form.$refs.form.validate(async (valid) => {
        if (valid) {
          !this.form.id ? this.add() : this.edit()
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },

    async add() {
      // this.form.image = this.imageTemp
      const res = await addProject(this.form)
      if (res.code === 200) {
        this.visible = false
        this.$message({
          type: "success",
          message: "添加成功",
        })
        this.getData()
      }
    },

    async edit() {
      // this.form.image = this.imageTemp
      const res = await updateProject(this.form.id, this.form)
      if (res.code === 200) {
        this.visible = false
        this.$message({
          type: "success",
          message: "修改成功",
        })
        this.getData()
      }
    },

    // 表单关闭按钮
    _Close() {
      this.closeForm()
    },

    // 关闭表单
    closeForm() {
      console.log(this.form)
      this.$refs.form.$refs.form.resetFields() // 重置表单
      this.$refs.form.$refs.form.clearValidate() // 移除表单校验
      this.visible = false // 关闭弹框
    },

    // 获取所属店铺
    async getStore() {
      try {
        const res = await getStoreOptions()
        const index = this.formItemList.findIndex((_) => _.flag === true)
        this.formItemList[index].options = res.data.records
      } catch (error) {
        console.log(error)
      }
    },

    // 获取项目分类
    async getProjectClassify() {
      try {
        const res = await getProjectClassifyOptions()
        const index = this.formItemList.findIndex(
          (_) => _.prop === "classifyId"
        )

        // this.formItemList[index].label = `this.formItemList[index].label(${this.formItemList[index2].label})`
        this.formItemList[index].options = res.data
      } catch (error) {
        console.log(error)
      }
    },

    // 获取数据
    async getData() {
      this.getStore()
      this.getProjectClassify()
      this.loading = true
      const { limit, page } = this.pagingOptions
      try {
        const res = await getProject(limit, page, {
          name: this.queryForm.name,
          storeId: this.queryForm.storeId,
        })
        this.loading = false
        this.tableData = res.data.list
        // this.tableData.forEach((_) => {
        //   // 回显图片
        //   _.image = this.baseURL + _.image
        // })
        this.pagingOptions.total = res.data.total
      } catch (error) {
        this.loading = false
        this.tableData = []
        this.pagingOptions.total = 0
        this.$message({
          showClose: true,
          message: "获取数据失败",
          type: "error",
        })
      }
    },

    /**
     * @description 上传头像
     *  @param {Object} file 文件
     */
    handleAvatarSuccess(file) {
      // this.form.image = URL.createObjectURL(file.raw)
      // this.imageTemp = file.response
      this.form.image = file.response
    },
  },
}
</script>

<style lang="scss" scoped>
.service-items-manage {
  height: 100%;
  padding: 0 16px 0 16px;
  background-color: #fff;
}
</style>